export const Logo = props => {
    return (
      <div id="logo-text-box">
        <a href="https://youtube.com/" target="_blank" rel="noreferrer">
          <img className="logo" src={props.logo}></img>
        </a>
        Squish-a-Plush
        <a href="https://youtube.com/" target="_blank" rel="noreferrer">
          <img className="logo" src={props.logo}></img>
        </a>
      </div>
    )
  }