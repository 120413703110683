import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Twitter = () => {
  return (
    <FontAwesomeIcon icon={["fab", "twitter"]} />
  );
}

export const Discord = () => {
  return (
    <FontAwesomeIcon icon={["fab", "discord"]} />
  );
}
